/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { Text } from "@react-three/drei";

import * as THREE from "three";

export default function Model(props) {
  const { nodes } = useGLTF("/models/tomas-smol.glb");
  return (
    <group {...props} dispose={null} scale={2.5} rotation={[0, Math.PI / 4, 0]}>
      {props.children}
      <mesh
        geometry={nodes.Mesh_0.geometry}
        material={new THREE.MeshNormalMaterial()}
      />

      <Text
        position={[-0.1, -0.5, -0.1]}
        rotation={[-Math.PI / 8, -Math.PI / 3, 0]}
        color={"black"}
        textAlign={"center"}
        fontSize={0.02}
        maxWidth={0.5}
        lineHeight={1}
        letterSpacing={0.02}
        anchorX="center"
        anchorY="middle"
      >
        {"What are you doing? stop looking at my feet!"}
      </Text>
    </group>
  );
}

useGLTF.preload("/models/tomas-smol.glb");
